
.Title {
    color: white;
}

.Title .title {
    font-size: 4rem;
}

.Title .subtitle {
    font-size: 1.25rem;
    font-style: italic;
}